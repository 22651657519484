// Set a Cookie
function setCookie(cName, cValue, expDays) {
	let date = new Date();
	date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
	const expires = "expires=" + date.toUTCString();
	document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}


const closeWinterModal = (sel, el = null) => {
	document.querySelector(sel).classList.remove('active')
	setCookie('winterglueck_banner', true, 5);
	if (el) {
		window.location = el.dataset.href
	}
	
}

function closePopup() {
  popup.removeClass("active");
}

$("#minimize").on("click", function() {
  $(".configurator .preview").toggleClass("narrow");
});
$(".previewHeading").on("click", function() {
  $(".configurator .preview").toggleClass("narrow");
});

// Popup Alert

const popupHinweis = {
  infoGewinn: {
    heading: "Gewinnspiel EUHA 2019",
    content: `
      <p><strong>Teilnahmebedingungen: </strong><br>
      Das Gewinnspiel läuft vom 16.10.- 18.10.2019. Es kann nur auf dem Oticon Messestand auf den iPads teilgenommen werden. Teilnehmen können alle, ausgenommen Mitarbeiter der Herstellerfirmen und Oticon-Mitarbeiter. Eine Barauszahlung der Gewinne ist nicht möglich. Die Gewinner werden per E-Mail benachrichtigt. Der Rechtsweg ist ausgeschlossen.<br><br>
      
      <strong>Die Preise:</strong>
        <span style="display: flex; align-items: center; margin-top: 20px">
        <img src="assets/img/gutschein.png" alt="" width="250" style="top: 20px: float: left; margin: 0px 20px 0 0">
            5x Amazon-Gutschein im Wert von 50 Euro
        </span>
      </p>
            
  
      `
  }
};

let alertMsg = $(".alert");
let info = $(".info");

alertMsg.on("click", function() {
  $("#popupAlert").addClass("active");

  if (
    $(this)
      .closest(".configSection")
      .hasClass("kanal")
  ) {
    let content = `
          <a class="close" onclick="closePopup()"></a>
          <h2>${popupAlert.heading}</h2>
          <h3>${popupAlert.subtitle}</h3>
          <div class="soft-row">
            <div onclick="link${popupAlert.alert1[0]}();" class="alertDevice">
              <img class="${popupAlert.alert1[0]}" src="assets/img/${
      popupAlert.alert1[2]
    }" alt="${popupAlert.alert1[1]}">
              <p>${popupAlert.alert1[1]}</p>
            </div>
          </div>`;
    $("#popupAlert .popupContent").html(content);
  } else {
    let alertContent = this.dataset.popup;
    var alertLinks = "";
    for (var i = 0; i < popupAlert[alertContent].length; i++) {
      alertLinks +=
        '<div onclick="link' +
        popupAlert[alertContent][i][0] +
        '();" class="alertDevice"><img class="' +
        popupAlert[alertContent][i][0] +
        '" src="assets/img/' +
        popupAlert[alertContent][i][2] +
        '" alt="' +
        popupAlert[alertContent][i][1] +
        '"><p>' +
        popupAlert[alertContent][i][1] +
        "</p></div>";
    }

    let content =
      '<a class="close" onclick="closePopup()"></a><h2>' +
      popupAlert.heading +
      "<h3>" +
      popupAlert.subtitle +
      '</h3><div class="soft-row">' +
      alertLinks +
      "</div>";
    $("#popupAlert .popupContent").html(content);
  }
});

// Popup Info

info.on("click", function() {
  let infoContent = this.dataset.popup;

  $("#popupInfo").addClass("active");

  let contentInfo = `
        <a class="close" onclick="closePopup()"></a>
        <div class="hinweisContent">
        <h2>${popupHinweis[infoContent].heading}</h2>
        ${popupHinweis[infoContent].content}</div>`;
  $("#popupInfo .popupContent").html(contentInfo);
});

$("#popupInfo").on("click", function() {
  $(".popup").removeClass("active");
});

function printPage() {
  // $('#printPage').show();
  // $('#printPage2').show();
  window.print();
}

$(".weiterBtn1").on("click", function() {
  if ($(this).hasClass("active")) {
  } else {
    $("#popupAuswahl").show();
  }
});

$("#popupAuswahl").on("click", function() {
  $("#popupAuswahl").hide();
});

$("#bc2").on("click", function() {
  if ($(this).hasClass("activated")) {
  } else {
    $("#popupAuswahl").show();
  }
});

$("body").on("click", function() {
  $("#printPage").hide();
});
