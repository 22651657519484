const data = [
    {
        frage: 'Wie heißt der neue Chip im neuen Hörsystem Oticon More?',
        antworten: [
            {
                antwort: 'Moralis',
                right: false
            },
            {
                antwort: 'Polaris',
                right: true
            },
            {
                antwort: 'Virtualis',
                right: false
            },
            {
                antwort: 'Sentalis',
                right: false
            }
        ]
    }
]
if (document.getElementById('quiz--content')) {
    const quizContainer = document.getElementById('quiz--content')
    const state = {
        question: 0,
        answer1: false,
        answer2: false,
        answer3: false,
        answer4: false,
        answer5: false,
        answer6: false,
        answer7: false,
        answer8: false,
        answer9: false,
        answer10: false,
        answer11: false,
        answer12: false,
    }
}

if (document.getElementById("app")) {
    const nextQuiz1 = document.querySelector(".btn1");
    const nextQuiz2 = document.querySelector(".btn2");
    const nextQuiz3 = document.querySelector(".btn3");
    const nextQuiz4 = document.querySelector(".btn4");
    const prevQuiz = document.querySelectorAll(".quizPrev");
    const slideQuiz = document.getElementById("q1");
    const resultQuiz = document.querySelector(".quizForm");
    const answer1 = document.getElementsByName("q1");
    const answer2 = document.getElementsByName("q2");
    const answer3 = document.getElementsByName("q3");
	const answer4 = document.getElementsByName("q4");


	
	const nextButtons = document.querySelectorAll(".quizNext");

	const validateQuestion = (question) => { 
		const checkboxes = question.parentNode.parentNode.querySelectorAll('input')
		let valid = false
		checkboxes.forEach(checkbox => { 
			if (checkbox.checked && checkbox.value === 'true') {
				valid = true
			} 
		})
		return valid
	}
	nextButtons.forEach((button) => { 
		
		button.addEventListener('click', () => {
			if (!validateQuestion(button)) {
				document.getElementById("popupInfo2").classList.add("active");
			} else { 
				document.getElementById("q1").style.marginLeft = `-${button.dataset.target}%`;
				if (button === nextButtons[nextButtons.length - 1]) { 
					resultQuiz.classList.add("active");
				}
				
			}
		})
	})


	prevQuiz.forEach(btn => { 
		btn.addEventListener("click", () => {
			resultQuiz.classList.remove("active");
			console.log(slideQuiz.style.marginLeft);
			slideQuiz.style.marginLeft = `-${btn.dataset.target}%`;
		});
	})

    $(".small").on("click", function() {
        $(this).removeClass("active");
    });

    // Enter Taste deaktivieren

    function stopRKey(evt) {
        var evt = evt ? evt : event ? event : null;
        var node = evt.target ? evt.target : evt.srcElement ? evt.srcElement : null;
        if (evt.keyCode == 13 && node.type == "text") {
            return false;
        }
    }

    document.onkeypress = stopRKey;
}
