const gebote = {}

const setGebot = () => {
	// const value = document.getElementById(id).value
	const inputs = document.querySelectorAll('[data-gebot]')

	inputs.forEach(i => {
		const value = i.value
		console.log(value);
		const title = i.dataset.title
		if (value) {
			if (isNaN(parseFloat(value))) {
				alert("Bitte eine gültige Zahl eingeben!")
				return
			}
			gebote[title] = {
				title: title,
				value: value
			}
		}
	})	
	
	renderGebote()	

}

const clearGebot = (el, key) => {
	// console.log(el.parentNode, key);
	delete gebote[key]
	console.log(gebote);
	renderGebote()
}

const renderGebote = () => {
	const container = document.getElementById('meinegebote')
	container.innerHTML = ""

	for (const key in gebote) {
		container.innerHTML += `
			<div class="meingebot">
				<input type="hidden" name="bild[]" value="${gebote[key].title}" />
				<input type="hidden" name="preis[]" value="${parseFloat(gebote[key].value).toFixed(2)}">
				<p>${gebote[key].title}: <strong>${parseFloat(gebote[key].value).toFixed(2).replace('.', ',')} €</strong></p>
				<div class="clear" onclick="clearGebot(this, '${key}')">&times;</div>
			</div>
		`
	}
	
}

//Modals

const openTnbs = sel => {
	document.querySelector(sel).classList.add('active')
 }
const closeTnbs = sel => {
	document.querySelector(sel).classList.remove('active')
 }