const ot_video = {
	titleElement: "",
	videoContainer: "",
	videoElements: "",

	init() { 
		this.titleElement = document.querySelector('[data-id=videoname]')
		this.videoContainer = document.querySelector('[data-el=ot__videocontainer]')
		this.videoElements = document.querySelectorAll('.youtube__content .video')
		this.thumbClick()
	},

	thumbClick() { 
		this.videoElements.forEach(el => { 
			el.addEventListener('click', () => { 
				const title = el.dataset.title
				const video = el.dataset.video
				const img = el.dataset.img
				this.videoElements.forEach(v => v.classList.remove('active'))
				el.classList.add('active')
				this.titleElement.innerText = title
				this.videoContainer.dataset.id = video
				this.videoContainer.dataset.img = img
				this.videoContainer.style.backgroundImage = `url('${img}')`
				this.videoContainer.innerHTML = ''
				initYouTubeVideos()
			})
		})
	}
}

window.addEventListener('DOMContentLoaded', () => { 
	ot_video.init()
})